@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

body {
  margin: 0;
  font-family: 'Inter', serif;
  overflow-x: hidden;
}

h1 {
  font-family: 'Rubik', sans-serif;;
}

.bg-img {
    background-image: url(./assets/img/NovoHero.png);
}
.card-img {
    background-image: url(./assets/img/novoCard.png);
}
.waitlist-img {
    background-image: url(./assets/img/waitlist.png);
}
